window.ArchivesHandler = (function (window, $) {
    const ArchivesHandler = {};
    const limit = 10;
    let nextUrl = null;
    let $notificationHolder = null;
    let lastRequest = null;
    let scroller;

    ArchivesHandler.init = function (url) {
        nextUrl = url;
        ArchivesHandler.bindEvents();
        MessageHandler.init();
        $notificationHolder = $('.js-notification-holder');
        ArchiveFilterHandler.init(ArchivesHandler.updateFilters);
        AccordianHandler.init();


        scroller = new InfiniteScrollHandler(ArchivesHandler.loadMore, $notificationHolder);
        ArchivesHandler.scroller = scroller;
    };

    ArchivesHandler.bindEvents = function () {
        $('body').on('click', '.js-retry-check-more', function () {
            if (lastRequest !== null) {
                lastRequest();
            }
        }).on('click', '.js-load-more', function () {
            scroller.startLoading();
            scroller.hideButton();
        });
    };


    ArchivesHandler.updateFilters = function () {
        lastRequest = ArchivesHandler.updateFilters;
        $notificationHolder.addClass('notification-holder--loading');

        Ajax.get('archives', ArchiveFilterHandler.getActiveFilters()).then(function (data) {
            $notificationHolder.removeClass('notification-holder--loading').html('');
            if (data.results.length > 0) {
                $(data.results).each(function (index, value) {
                    $notificationHolder.append($(value.content).addClass(index <= 3 ? 'notification--main notification--open' : ''));
                });
            } else {
                $notificationHolder.append(
                    $('<div>').addClass('notification notification--none')
                              .append($('<h2>').text('No notifications'))
                              .append($('<p>').text('There are no notifications to show with your current configuration of filters.')),
                );
            }
            nextUrl = data.next;
            if (data.results.length >= limit) {
                scroller.showButton();
            } else {
                scroller.hideButton();
            }
            scroller.stopLoading();
        }, () => {
            MessageHandler.showError('We couldn\'t load any filtered notifications, please <a href="#" class="js-retry-check-more">retry</a> to load notification that match the filters you have applied.');
        });
    };

    ArchivesHandler.loadMore = function () {
        lastRequest = ArchivesHandler.loadMore;
        return Ajax.get(nextUrl, {}).then(function (data) {
            $(data.results).each(function (index, value) {
                $notificationHolder.append(value.content);
            });
            if (data.results.length < limit) {
                scroller.stopLoading();
                scroller.hideButton();
            }
            nextUrl = data.next;
        }, () => {
            MessageHandler.showError('We couldn\'t load any new notifications, please <a href="#" class="js-retry-check-more">retry</a> to load new notifications.');
        });
    };

    return ArchivesHandler;
})(window, jQuery);
