function eotwJSXFactory(elementName, attributes, children) {
    let $element = $("<" + elementName + ">");
    if ("object" === typeof attributes && null !== attributes) {
        if ("undefined" !== typeof attributes['unsafeHtml']) {
            $element.html(attributes['unsafeHtml']);
            delete attributes['unsafeHtml'];
        }
        if ("undefined" !== typeof attributes['className']) {
            if ("string" !== typeof attributes['class']) {
                attributes['class'] = '';
            }
            attributes['class'] += ' ' + attributes['className'];
            delete attributes['className'];
        }

        $element.attr(attributes);
    }

    handleChildren($element, children);
    if (arguments.length > 3) {
        for (let i = 3; i < arguments.length; i++) {
            handleChildren($element, arguments[i]);
        }
    }
    return $element;
}

function handleChildren($element, children) {
    if (children === null) return;
    if ($.isArray(children)) {
        children.forEach(handleChildren.bind(this, $element));
    } else if ("string" === typeof children) {
        $element.append(document.createTextNode(children));
    } else {
        $element.append(children);
    }
}
