class RangeInput {
    constructor($wrap) {
        this.$wrap = $wrap;

        this.$slider = $wrap.find(".js-slider");

        this.$fromLabel = $wrap.find(".js-slider-from-value");
        this.$toLabel = $wrap.find(".js-slider-to-value");
        this.$units = $wrap.find(".js-unit");

        this.$fromInput = $wrap.find(".js-from-input");
        this.$toInput = $wrap.find(".js-to-input");

        this.unit = null;

        RangeInput.instances.push(this);

        this.bindEvents();
    }

    setUnit(unit_id) {
        let unit = null;
        window.UNITS.forEach((_unit) => {
            if (_unit.id == unit_id) unit = _unit;
        });
        this.unit = unit;
        if (unit === null) {
            this.slider.updateOptions({
                range: {
                    min: 0,
                    max: 100,
                },
            }, false);
            this.slider.set([0, 100]);
            return;
        }

        let maxValueNormalised = window.MAX_CAPACITY_VALUES[unit.type.id][this.$wrap.attr("data-name")];
        let maxValue = Math.ceil(unit.conversionMultiplier === null ? maxValueNormalised : maxValueNormalised / unit.conversionMultiplier);

        this.slider.updateOptions({
            range: {
                min: 0,
                max: maxValue,
            },
        }, false);
        this.slider.set([0, maxValue]);
    }

    bindEvents() {

        this.slider = noUiSlider.create(this.$slider[0], {
            start: [0, 100],
            connect: true,
            range: {
                'min': 0,
                'max': 100,
            },
        });

        this.slider.on("start", () => {
            if (this.unit === null) {
                $("input[name=unit][value=1]").attr("checked", true);
                RangeInput.instances.forEach((input) => input.setUnit(1));
            }
        });
        this.slider.on("update", () => {
            this.updateLabels();
        });
        this.slider.on("change", () => {
            $(".js-filter-form").submit();
        });

        const me = this;
        this.$fromInput.on("change", function () {
            me.slider.set([$(this).val(), null]);
        });
        this.$toInput.on("change", function () {
            me.slider.set([null, $(this).val()]);
        });
    }

    updateLabels() {
        let [min, max] = this.slider.get();

        if (this.unit === null) {
            this.$units.html("&nbsp;");
            this.$fromLabel.text("");
            this.$toLabel.text("");
            this.$fromInput.val("");
            this.$toInput.val("");
        } else {
            this.$units.text(this.unit.name);
            this.$fromLabel.text(parseFloat(min).toFixed(0));
            this.$toLabel.text(parseFloat(max).toFixed(0));

            if (min > this.slider.options.range.min) {
                this.$fromInput.val(min);
            } else {
                this.$fromInput.val("");
            }
            if (max < this.slider.options.range.max) {
                this.$toInput.val(max);
            } else {
                this.$toInput.val("");
            }
        }
    }

    getTextValue() {
        if (!this.anySelected()) {
            return "All";
        }

        let [min, max] = this.getValue();

        if (parseInt(min) === parseFloat(min)) {
            min = parseInt(min);
        }
        if (parseInt(max) === parseFloat(max)) {
            max = parseInt(max);
        }


        return min + this.unit.name + " - " + max + this.unit.name;
    }

    anySelected() {
        let [min, max] = this.slider.get();

        if (min > this.slider.options.range.min || max < this.slider.options.range.max) {
            return true;
        }

        return false;
    }

    setValue(min, max) {
        if (min === "") min = 0;
        if (max === "") max = this.slider.options.range.max;
        this.slider.set([min, max]);
    }

    getValue() {
        return this.slider.get();
    }

    reset() {
        this.slider.set([0, this.slider.options.range.max]);
    }

    static reset_all() {
        RangeInput.instances.forEach((input) => input.reset());
    }

    static any_selected() {
        let any_selected = false;

        RangeInput.instances.forEach((input) => any_selected = any_selected || input.anySelected());

        return any_selected;
    }
}

RangeInput.instances = [];
