class InfiniteScrollHandler {

    constructor(_callback, elm) {
        this.autoLoadsRemaining = 3;
        this.enabled = false;
        this.loadingMore = false;

        this.callback = _callback;
        this.$elm = elm;

        this.bindEvents();
    };

    bindEvents() {
        $(window).on('scroll', () => this.check());
    };

    startLoading() {
        this.enabled = true;
        this.doCallback();
    };

    stopLoading() {
        this.enabled = false;
        this.autoLoadsRemaining = 3;
    };

    check() {
        if (!this.enabled || this.loadingMore) return;
        if ($(window).scrollTop() + $(window).height() > this.$elm.position().top + this.$elm.height() - ($(window).height() / 2.5)) {
            this.doCallback();
        }
    };

    doCallback() {
        this.autoLoadsRemaining--;
        this.loadingMore = true;
        this.$elm.find(".js-load-more").addClass("btn--loading");
        this.callback().then(() => {
            this.loadingMore = false;
            if (this.autoLoadsRemaining === 0) {
                this.showButton();
                this.stopLoading();
            }

            this.$elm.find(".js-load-more").removeClass("btn--loading");
        }, () => {
            this.loadingMore = false;
            this.showButton();
            this.stopLoading();

            this.$elm.find(".js-load-more").removeClass("btn--loading");
        });
    };

    showButton() {
        this.$elm.find('.js-load-more-holder').addClass("button-holder--shown");
    };

    hideButton() {
        this.$elm.find('.js-load-more-holder').removeClass("button-holder--shown");
    };
}
