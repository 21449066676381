window.SubscribeForm = (function (window, $) {

    function SubscribeForm($form) {
        this.$form = $form;

        function doSubscribe(token) {
            const $btn = $form.find(".btn[type='submit']").addClass("btn--loading");
            const email = $form.find(".js-email").val();

            Ajax.post('subscribe', {
                email: email,
                "g-recaptcha-response": token,
            }).then(function () {
                $btn.removeClass('btn--loading');
                const message = '<div class="sm success-message">\
                                    <span class="sm__icon"><svg><use xlink:href="/includes/svg-icons.svg?v=1#subscription-confirm-icon"></use></svg></span>\
                                    <span class="sm__info">\
                                        <h4>Confirm your subscription</h4>\
                                        <p>Almost done - we’ve sent an email to <strong>' + email + '</strong>. Follow the instructions in that email to confirm your subscription.</p>\
                                    </span>\
                                </div>';
                $('.js-subscribe-form').html(message);
                grecaptcha.reset();
            }, function (error) {
                $btn.removeClass('btn--loading');
                $form.find(".js-email").data("Validator").showMessage(error.message, 'invalid');
                grecaptcha.reset();
            });
        }

        $form.data("SubscribeForm", this);

        this.validator = FormValidator.attach($form, {
            inputs: {
                subscribeEmail: {
                    messages: {
                        empty: "Please enter a valid email address",
                    },
                },
            },
        });

        this.validator.on('submit', function ($form) {
            const $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

            try {
                window.onRecaptchaSubmit = doSubscribe;
                grecaptcha.execute();
            } catch (e) {
                console.warn(e);
                $btn.removeClass("btn--loading");
                $form.find(".js-email").data("Validator").showMessage('There was an error submitting your message. Please try again later.', 'invalid');
            }
        });
    }

    SubscribeForm.init = function () {
        $(".js-subscribe-form").each(function () {
            new SubscribeForm($(this));
        });
    };

    return SubscribeForm;
})(window, jQuery);
