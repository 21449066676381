window.NewsHandler = (function (window, $) {
    const NewsHandler = {};
    const limit = 10;

    let nextUrl = false;
    let $notificationHolder = null;
    let scroller;

    NewsHandler.init = function (url) {
        nextUrl = url;
        $notificationHolder = $('.js-notification-holder');

        scroller = new InfiniteScrollHandler(NewsHandler.loadMore, $('.js-page-content'));
        AccordianHandler.init();
        MessageHandler.init();
        NewsHandler.bindEvents();
    };

    NewsHandler.bindEvents = function () {
        $('body').on('click', '.js-load-more', function () {
            scroller.startLoading();
            scroller.hideButton();
            NewsHandler.loadMore();
        });
    };

    NewsHandler.loadMore = function () {
        return Ajax.get(nextUrl, {}).then(function (data) {
            $(data.results).each(function (index, value) {
                $notificationHolder.append(value.content);
            });
            if (data.results.length < limit) {
                scroller.stopLoading();
                scroller.hideButton();
            }
            nextUrl = data.next;
        }, function (error) {
            const text = error.message;
            MessageHandler.showError(text);
        });
    };

    return NewsHandler;
})(window, jQuery);
