window.NavigationHandler = (function (window, $) {
    var NavigationHandler = {};

    NavigationHandler.init = function () {
        NavigationHandler.bindEvents();
    };

    NavigationHandler.bindEvents = function () {
        $('body').on('click', '.js-nav-toggle-open', function () {
            NavigationHandler.open();
            return false;
        }).on('click', '.js-nav-toggle-close', function () {
            NavigationHandler.close();
            return false;
        }).on('click touch', function (e) {
            var $target = $(e.target);
            if (!$target.is("nav") && $target.parents("nav").length == 0) {
                NavigationHandler.close();
            }
        });
    };

    NavigationHandler.open = function () {
        $('body').addClass('show-navigation');
    };

    NavigationHandler.close = function () {
        $('body').removeClass('show-navigation');
    };

    return NavigationHandler;
})(window, jQuery);
