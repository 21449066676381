class Ajax {

    static request(method, action, requestData) {
        let cancelled = false;
        let promise;
        let jqXHR;
        const csrf = CookieHelper.get('csrf');

        if (method !== 'GET') {
            if (!requestData) {
                requestData = {
                    csrf: csrf,
                };
            } else if ('object' === typeof requestData) {
                requestData['csrf'] = csrf;
            } else {
                //Noop Request will be rejected
            }
        }

        promise = new Promise(function (resolve, reject) {
            jqXHR = $.ajax({
                url: "/api/" + action,
                type: method,
                data: requestData,
                dataType: "json",
            }).done(function (data) {
                if (cancelled) return;

                if (data.success) {
                    resolve(data.data);
                } else {
                    if (data.exception && data.exception == "UnacceptedTermsException") {
                        TermsHandler.showTerms().then(function () {
                            Ajax.request(method, action, requestData).then(resolve, reject);
                            sweetAlert.close();
                        });
                    } else {
                        const error = new Error(data.message);
                        error.data = data;
                        reject(error);
                    }
                }
            }).fail(function (jqXHR) {
                if (cancelled) return;
                let data = jqXHR.responseJSON;
                let error;

                if ("object" === typeof data) {
                    if (data.exception && data.exception == "UnacceptedTermsException") {
                        TermsHandler.showTerms().then(function () {
                            Ajax.request(method, action, requestData).then(resolve, reject);
                            sweetAlert.close();
                        });
                        return;
                    } else {
                        error = new Error(data.message);
                        error.data = jqXHR.responseJSON;
                    }
                } else {
                    error = new Error("Connection error occurred");
                    error.data = undefined;
                }
                reject(error);
            });
        });
        Object.defineProperty(promise, 'abort', {
            value: function () {
                cancelled = true;
                if (jqXHR) jqXHR.abort();
                return this;
            },
        });

        return promise;
    }

    static get(action, data) {
        return Ajax.request("GET", action, data);
    }

    static post(action, data) {
        return Ajax.request("POST", action, data);
    }

    static delete(action, data) {
        return Ajax.request("DELETE", action, data);
    }

    static put(action, data) {
        return Ajax.request("PUT", action, data);
    }
}
