Page.register("/", function (url_args) {
    const TAB_COOKIE = 'default-homepage-tab';
    $("body").on("click", ".js-tab", function () {
        showTab($(this).attr("data-tab"));
        return false;
    }).on("click", ".js-csv-download", function () {
        let url = '/csv-download?' + $.param($.extend(true, {format: 'csv'}, FilterHandler.getActiveFilters()));

        window.location.href = url;

        return false;
    });

    function showTab(tab) {
        let $tab = $(".js-tab-content[data-tab='" + tab + "']");
        if ($tab.length === 0) return;

        let $tabButton = $(".js-tab[data-tab='" + tab + "']");
        $(".js-tab-content").not($tab).hide();
        $tab.show();
        $(".js-tab").not($tabButton).removeClass("view-tab--active");
        $tabButton.addClass("view-tab--active");

        $(".js-tab-title").text($tab.find(".page-header").text().trim());

        if (!$tab.is(".page__content--loaded")) {
            console.log("Loading page content", tab);

            switch (tab) {
                case "feed":
                    NotificationFeedHandler.init();
                    break;
                case "table":
                    NotificationTableHandler.init();
                    break;
                default:
                    console.error("Unknown tab: " + tab);
            }

            $tab.addClass("page__content--loaded");
        }

        try {
            if (tab !== 'csv') {
                CookieHelper.set(TAB_COOKIE, tab);
            }
        } catch (e) {
            console.log("Couldn't set default tab", e);
        }
    }

    AccordianHandler.init();

    try {
        let tab = CookieHelper.get(TAB_COOKIE);

        if ("string" === typeof tab) {
            showTab(tab);
        }
    } catch (e) {
        console.log("Couldn't load default tab", e);
    }
});
