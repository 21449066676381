window.MessageHandler = (function (window, $) {
    var MessageHandler = {};
    var slideDuration = 300;
    var $elm = null;

    MessageHandler.init = function () {
        MessageHandler.bindEvents();
        $elm = $('.js-error-holder');
    };

    MessageHandler.bindEvents = function () {
        // $('body').on('click','.js-retry',function(){
        //     //do stuff
        // });
    };

    MessageHandler.showError = function (text) {
        $elm.html(MessageHandler.buildMessage(text, 'error'));
        MessageHandler.showMessage();
    };

    MessageHandler.showSuccess = function (text) {
        $elm.html(MessageHandler.buildMessage(text, 'success'));
        MessageHandler.showMessage();
    };

    MessageHandler.showWarning = function (text) {
        $elm.html(MessageHandler.buildMessage(text, 'warning'));
        MessageHandler.showMessage();
    };

    MessageHandler.showInfo = function (text) {
        $elm.html(MessageHandler.buildMessage(text, 'info'));
        MessageHandler.showMessage();
    };

    MessageHandler.showMessage = function () {
        if ($elm.length === 0) {
            return;
        }
        $elm.stop().slideDown(slideDuration);
        $('html, body').animate({
            scrollTop: $elm.offset().top - $('header').height() - 80,
        }, 1000);
    };

    MessageHandler.hideMessage = function () {
        $elm.stop().slideUp(slideDuration, function () {
            $elm.html('');
        });
    };

    MessageHandler.buildMessage = function (text, type) {
        return $('<div>').addClass('message message--' + type + ' js-new-notifications-' + type).html($('<p>').html(text));
    };

    MessageHandler.buildMessageWithLink = function (text, type, link, linkText) {
        return $('<div>').addClass('message message--' + type + ' js-new-notifications-' + type).html($('<p>').html(text).append($('<a>').text(linkText).attr('href', link)));
    };

    MessageHandler.setElm = function (elm) {
        $elm.html(elm);
    };

    return MessageHandler;
})(window, jQuery);
