window.AccordianHandler = (function (window, $) {
    const AccordianHandler = {};

    AccordianHandler.init = function () {
        AccordianHandler.bindEvents();
    };

    AccordianHandler.bindEvents = function () {
        $('body').on('click', '.js-open-notification', function () {
            return AccordianHandler.toggleOpen($(this)) === false;
        });
    };

    AccordianHandler.toggleOpen = function ($elm) {
        const $parent = $elm.parents('.notification');
        if ($parent.is('.notification--main')) return false;
        if ($parent.is('.notification--open')) {
            AccordianHandler.close($parent);
        } else {
            AccordianHandler.open($parent);
        }
        return true;
    };

    AccordianHandler.open = function ($elm) {
        $elm.addClass('notification--open');
        $elm.find('.notification__info').slideDown({
            duration: 300,
            easing: 'linear',
        });
    };

    AccordianHandler.close = function ($elm) {
        $elm.removeClass('notification--open');
        $elm.find('.notification__info').slideUp({
            duration: 300,
            easing: 'linear',
        });
    };

    return AccordianHandler;
})(window, jQuery);
