window.NotificationTableHandler = (function (window, $) {
    const NotificationTableHandler = {};
    const limit = 10;
    let nextUrl = null;
    let $notificationHolder = null;
    let lastRequest = null;
    let scroller;
    let $tab;
    let updatejQXHR = null;


    NotificationTableHandler.init = function (url = null) {
        nextUrl = url;
        MessageHandler.init();
        $notificationHolder = $('.js-tablular-notifications');
        $tab = $(".js-tab-content[data-tab='table']");

        scroller = new InfiniteScrollHandler(NotificationTableHandler.loadMore, $tab);

        NotificationTableHandler.bindEvents();

        FilterHandler.init(NotificationTableHandler.updateFilters);

        if (url === null) {
            NotificationTableHandler.updateFilters();
        }
    };

    NotificationTableHandler.bindEvents = function () {
        $tab.on('click', '.js-retry-check-more', function () {
            if (lastRequest !== null) {
                lastRequest();
            }
        }).on('click', '.js-load-more', function () {
            scroller.startLoading();
        }).on("click", ".js-toggle-details", function () {
            let id = $(this).parents("tr").attr("data-notification-id");
            let $details = $(".js-full-row[data-notification-id='" + id + "']");

            $details.toggle();
            $(this).toggleClass('tn__more-info--open', $details.is(":visible"));
            $("body").toggleClass("show-notification", $details.is(":visible"));
        }).on("click", ".js-close-notification-details", function () {
            $(".js-full-row").hide();
            $(".js-toggle-details").removeClass('tn__more-info--open');
            $("body").removeClass("show-notification");
        }).on("change", ".js-order-by", function () {
            $(".js-order-by-text").text($(".js-order-by option:selected").text());
            NotificationTableHandler.updateFilters();
        });
    };

    NotificationTableHandler.getOrderSettings = function () {
        let $active = $(".js-order-by option:selected");

        return {
            order: $active.attr("data-field"),
            order_direction: $active.attr("data-direction"),
        };
    };


    NotificationTableHandler.updateFilters = function () {
        lastRequest = NotificationTableHandler.updateFilters;
        if (updatejQXHR) updatejQXHR.abort();

        scroller.hideButton();

        $notificationHolder.addClass('tabular-notifications-wrap--loading');

        let filters = $.extend(true, {
            mode: 'table',
            limit,
        }, NotificationTableHandler.getOrderSettings(), FilterHandler.getActiveFilters());
        updatejQXHR = Ajax.get('remits', filters);

        Promise.all([updatejQXHR, new Promise((resolve) => setTimeout(resolve, 600))]).then(function (args) {
            MessageHandler.hideMessage();
            let data = args[0];
            $notificationHolder.find("> table").show();

            let $tbody = $notificationHolder.find(".js-table-body");
            $notificationHolder.removeClass('tabular-notifications-wrap--loading');
            $tbody.html('');

            if (data.results.length > 0) {
                $(data.results).each(function (index, value) {
                    $tbody.append(value.tableContent);
                });
            } else {
                console.log(filters);
                $tbody.append(
                    <tr>
                        <td colspan="9">
                            <div className="notification notification--none">
                                <h2>No events</h2>
                                <p>{filters.type == 2 ? "Only Gas REMITs are displayed when using Table View. You can view Common REMITs via the Notification Feed." : "There are no events to show with your current configuration of filters."}</p>
                            </div>
                        </td>
                    </tr>,
                );
            }
            nextUrl = data.next;

            if (data.results.length >= limit) {
                scroller.showButton();
            } else {
                scroller.hideButton();
            }
            scroller.stopLoading();
        }, function () {
            MessageHandler.showError('We couldn\'t load any filtered notifications, please <a href="#" class="js-retry-check-more">retry</a> to load notification that match the filters you have applied.');
        });
    };

    NotificationTableHandler.loadMore = function () {
        lastRequest = NotificationTableHandler.loadMore;

        $tab.find(".js-load-more").addClass("btn--loading");

        return Ajax.get(nextUrl, {limit}).then(function (data) {
            MessageHandler.hideMessage();

            $tab.find(".js-load-more").removeClass("btn--loading");
            let $tbody = $notificationHolder.find(".js-table-body");
            $(data.results).each(function (index, value) {
                $tbody.append(value.tableContent);
            });
            if (data.results.length < limit) {
                scroller.stopLoading();
                scroller.hideButton();
            }
            nextUrl = data.next;
        }, function () {
            $tab.find(".js-load-more").removeClass("btn--loading");
            MessageHandler.showError('We couldn\'t load any new notifications, please <a href="#" class="js-retry-check-more">retry</a> to load new notifications.');
        });
    };

    return NotificationTableHandler;
})(window, jQuery);
