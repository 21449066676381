class DateRangeInput {
    constructor($wrap) {
        this.$wrap = $wrap;
        this.$popup = $wrap.find(".js-date-popup");
        this.preset = null;
        this.name = $wrap.attr("data-name");

        DateRangeInput.instances.push(this);

        this.bindEvents();

        this.setPreset('all');
    }

    bindEvents() {
        this.$wrap.on("focus", ".js-date-display", () => this.open());

        const me = this;
        this.$wrap.on("click", ".js-date-preset", function () {
            me.setPreset($(this).attr("data-option"));
        }).on("click", ".js-save-dates", () => {
            this.close();
            return false;
        }).on("click", ".js-clear-dates", () => {
            this.setPreset('all');
            this.close();
            return false;
        });

        $("body").on('click touch', (e) => {
            if (!this.isOpen()) return;
            const $target = $(e.target);
            if ((!$target.is(".fh__date-input-icon-wrap") && $target.parents(".fh__date-input-icon-wrap").length === 0) && !$target.is(".fh__date-popup") && $target.parents(".fh__date-popup").length === 0) {
                this.close();
            }
        });

        let filterStartDate = '2017-01-01';

        this.$fromPicker = this.$wrap.find(".js-date-input-from").datepicker({
            format: 'yyyy-mm-dd',
            startDate: filterStartDate,
            viewMode: 2,
            maxViewMode: 2,
            container: this.$wrap.find(".js-datepicker-from"),
        }).on('changeDate change', () => {
            this.$fromMobilePicker.val(this.$fromPicker.val());
            this.setPreset('custom');
            this.updatePreview();
        }).on("show", () => {
            this.showPicker("from");
        }).on("hide", () => this.hidePicker("from"));

        this.$toPicker = this.$wrap.find(".js-date-input-to").datepicker({
            format: 'yyyy-mm-dd',
            startDate: filterStartDate,
            viewMode: 2,
            maxViewMode: 2,
            container: this.$wrap.find(".js-datepicker-to"),
        }).on('changeDate change', () => {
            this.$toMobilePicker.val(this.$toPicker.val());
            this.setPreset('custom');
            this.updatePreview();
        }).on("show", () => {
            this.showPicker("to");
        }).on("hide", () => this.hidePicker("to"));

        this.$fromMobilePicker = $(".js-mobile-datepicker[data-name='" + this.name + "_from" + "']").datepicker({
            format: 'yyyy-mm-dd',
            startDate: filterStartDate,
            viewMode: 2,
            maxViewMode: 2,
            container: $(".js-mobile-datepicker-holder[data-name='" + this.name + "_from" + "']"),
        }).on('changeDate change', (e) => {
            this.$fromPicker.val(this.$fromMobilePicker.val());
            this.setPreset('custom');
            this.updatePreview();
            if (e.viewMode === 'days') {
                FilterHandler.showParentSection();
            }
        });

        this.$toMobilePicker = $(".js-mobile-datepicker[data-name='" + this.name + "_to" + "']").datepicker({
            format: 'yyyy-mm-dd',
            viewMode: 2,
            maxViewMode: 2,
            startDate: filterStartDate,
            container: $(".js-mobile-datepicker-holder[data-name='" + this.name + "_to" + "']"),
        }).on('changeDate change', (e) => {
            this.$toPicker.val(this.$toMobilePicker.val());
            this.setPreset('custom');
            this.updatePreview();
            if (e.viewMode === 'days') {
                FilterHandler.showParentSection();
            }
        });
    }

    updatePreview() {
        this.$wrap.find(".js-date-display").val(this.getPreviewText());
    }

    getPreviewText() {
        if (this.preset === 'custom') {
            let from = this.$fromPicker.val();
            let to = this.$toPicker.val();

            if (from.length > 0 && to.length > 0) {
                return from + " to " + to;
            } else if (from.length > 0) {
                return "From " + from;
            } else if (to.length > 0) {
                return "Until " + to;
            } else {
                return 'All Dates';
            }

        } else if (this.preset === 'all') {
            return 'All Dates';
        } else {
            return DateRangeInput.OPTIONS[this.preset].text;
        }
    }

    showPicker(which) {
        this.$popup.addClass("fh__date-popup--show-" + which);
    }

    hidePicker(which) {
        this.$popup.removeClass("fh__date-popup--show-" + which);
    }

    open() {
        DateRangeInput.instances.forEach((other) => {
            if (other !== this) {
                other.close();
            }
        });
        this.$popup.addClass("fh__date-popup--open");
    }

    close() {
        if (!this.isOpen()) return;
        this.updatePreview();
        this.$fromPicker.trigger("value-changed");
        this.$popup.removeClass("fh__date-popup--open");
    }

    isOpen() {
        return this.$popup.is(".fh__date-popup--open");
    }

    setPreset(presetName) {
        if (this.preset === presetName) return;

        let preset = DateRangeInput.OPTIONS[presetName];
        if ("undefined" === typeof preset) {
            console.error("Invalid DateRangeInput preset", presetName);
            return;
        }
        this.preset = presetName;

        this.$wrap.find(".js-date-preset").removeClass("fh__date-button--active");
        this.$wrap.find(".js-date-preset[data-option='" + presetName + "']").addClass("fh__date-button--active");

        //Clear
        if (preset === null) {
            this.$toPicker.val("");
            this.$toMobilePicker.val("");
            this.$fromPicker.val("");
            this.$fromMobilePicker.val("");
            this.updatePreview();
            return;
        }

        //Custom
        if (preset === "") {
            return;
        }

        let fromDatepicker = this.$fromPicker.data("datepicker");
        fromDatepicker.viewDate = moment().add(preset.from, 'day').toDate();
        fromDatepicker.dates.replace(fromDatepicker.viewDate);
        fromDatepicker.setValue();


        let toDatepicker = this.$toPicker.data("datepicker");
        toDatepicker.viewDate = moment().add(preset.to, 'day').toDate();
        toDatepicker.dates.replace(toDatepicker.viewDate);
        toDatepicker.setValue();

        this.updatePreview();
    }

    static close_all() {
        this.instances.forEach((date) => date.close());
    }
}

DateRangeInput.instances = [];

DateRangeInput.OPTIONS = {
    today: {
        from: 0,
        to: 1,
        text: "Today",
    },
    next7: {
        from: 0,
        to: 7,
        text: "Next 7 Days",
    },
    next30: {
        from: 0,
        to: 30,
        text: "Next 30 Days",
    },
    nextYear: {
        from: 0,
        to: 365,
        text: "Next Year",
    },
    yesterday: {
        from: -1,
        to: 0,
        text: "Yesterday",
    },
    previous7: {
        from: -7,
        to: 0,
        text: "Previous 7 Days",
    },
    previous30: {
        from: -30,
        to: 0,
        text: "Previous 30 Days",
    },
    previousYear: {
        from: -365,
        to: 1,
        text: "Previous Year",
    },
    custom: "",
    all: null,
};
