window.ArchiveFilterHandler = (function (window, $) {
    const ArchiveFilterHandler = {};

    let callback = null;
    let datepicker = null;

    const notificationFilters = {
        publication_date: null,
        limit: 10,
    };
    let $filteredSection = null;
    let isFiltered = false;
    const sidebarFilters = {
        publication_date: null,
    };

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    ArchiveFilterHandler.init = function (initCallback) {
        callback = initCallback;
        $filteredSection = $('.js-filtered');
        ArchiveFilterHandler.bindEvents();
    };

    ArchiveFilterHandler.bindEvents = function () {
        $('body').on('click', '.js-filter-button', function () {
            if ($(this).is('.fh__button--disabled')) return;
            ArchiveFilterHandler.toggleFilterSectionShown($(this));
            if ($(this).attr('data-id') === 'publication_date' && datepicker !== null) {
                datepicker.datepicker('show');
            }
            return false;
        }).on('click', '.js-show-mobile-filter', function () {
            ArchiveFilterHandler.showFilters();
            return false;
        }).on('click', '.js-toggle-filter', function () {
            if ($('body').is('.show-filters')) {
                ArchiveFilterHandler.hideFilters();
            } else {
                ArchiveFilterHandler.showFilters();
            }
            return false;
        }).on('click touch', function (e) {
            const $target = $(e.target);
            if (!$target.is("fh") && $target.parents(".fh").length === 0 && $(window).width() < 760) {
                ArchiveFilterHandler.hideFilters();
            }
            if (!$target.is('.fh__view-container--drop-down') && $target.parents(".fh__view-container--drop-down").length === 0) {
                $('.fh__view-container--drop-down').removeClass('fh__view-container--shown');
                $('.js-filter-button').removeClass('fh__button--active');
            }
        }).on('click', '.js-filter-apply', function () {
            const viewId = $(this).parents('.js-view').length > 0 ? $(this).parents('.js-view').attr('data-id') : 'main';
            if (viewId === 'main') {
                ArchiveFilterHandler.updateResults();
                ArchiveFilterHandler.hideFilters();
            } else {
                ArchiveFilterHandler.updatePublicationDate();
                ArchiveFilterHandler.updateFilterSmallText();
                ArchiveFilterHandler.hideView(viewId);
            }
            return false;
        }).on('click', '.js-filter-cancel', function () {
            if ($(window).width() >= 760) return true;
            const viewId = $(this).parents('.js-view').length > 0 ? $(this).parents('.js-view').attr('data-id') : 'main';
            if (viewId === 'main') {
                ArchiveFilterHandler.resetSidebarFilters();
                ArchiveFilterHandler.resetFilterStates();
                ArchiveFilterHandler.updateFilterSmallText();
                ArchiveFilterHandler.hideFilters();
            } else {
                $(this).parents('.js-view').find('.js-input-value').val(sidebarFilters[viewId]);
                ArchiveFilterHandler.hideView(viewId);
            }
            return false;
        }).on('click', '.js-filter-clear', function () {
            const viewId = $(this).parents('.js-view').attr('data-id');
            ArchiveFilterHandler.clearSection(viewId);
        }).on('click', '.js-filter-remove', function () {
            $('.js-date-input').val(null).change();
        }).on('change', '.js-date-input', function () {
            if ($(window).width() >= 760) {
                ArchiveFilterHandler.updatePublicationDate();
            }
        });

        datepicker = $('.js-date-input').datepicker({
            format: 'yyyy-mm',
            viewMode: 2,
            minViewMode: 1,
            startDate: new Date('2012-01-01'),
            endDate: new Date(window.ARCHIVE_START_DATE),
            container: $('.js-datepicker-desktop'),
            autoclose: false,
        }).on('changeDate', function (ev) {
            if (ev.viewMode === 'months') {
                $(this).val(ev.date.getFullYear() + '-' + ("0" + (ev.date.getMonth() + 1)).slice(-2)).change();
            }
        });
    };

    ArchiveFilterHandler.getActiveFilters = function () {
        return {
            publication_date: notificationFilters.publication_date,
            limit: 10,
        };
    };

    ArchiveFilterHandler.updatePublicationDate = function () {
        let dateVal = $('.js-date-input').val();

        sidebarFilters['publication_date'] = dateVal !== '' ? dateVal : null;
        ArchiveFilterHandler.removeFilteredItem('publication_date', 1);
        if (dateVal !== '') {
            const date = moment(dateVal + '-01');
            const text = date.format('MMMM YYYY');
            ArchiveFilterHandler.addFilteredItem(text, 'publication_date', 1);
        }
        ArchiveFilterHandler.updateResults();
    };

    ArchiveFilterHandler.resetFilterStates = function () {
        $('.fh__view-container--drop-down .js-input-value').val(notificationFilters.publication_date);
    };

    ArchiveFilterHandler.updateSidebarSection = function (viewId) {
        sidebarFilters[viewId] = [];
        $('.js-view[data-id="' + viewId + '"]').find('.js-input-value').each(function () {
            if ($(this).is(":checked")) {
                sidebarFilters[viewId].push(parseInt($(this).val()));
            }
        });
    };

    ArchiveFilterHandler.toggleFilterSectionShown = function ($elm) {
        const id = $elm.attr('data-id');
        if ($elm.hasClass('fh__button--active')) {
            ArchiveFilterHandler.hideView(id);
        } else {
            ArchiveFilterHandler.showView(id);
        }
    };

    ArchiveFilterHandler.showView = function (viewId) {
        ArchiveFilterHandler.resetViews();
        $('.js-filter-button[data-id="' + viewId + '"]').addClass('fh__button--active');
        $('.js-view[data-id="' + viewId + '"]').addClass('fh__view-container--shown');
    };

    ArchiveFilterHandler.hideView = function (viewId) {
        ArchiveFilterHandler.resetViews();
        $('.js-view[data-id="' + viewId + '"]').removeClass('fh__view-container--shown');
    };

    ArchiveFilterHandler.resetViews = function () {
        $('.js-filter-button').removeClass('fh__button--active');
        $('.js-view').removeClass('fh__view-container--shown');
        $('.js-view[data-id="main"]').addClass('fh__view-container--shown');
    };

    ArchiveFilterHandler.clearSection = function (viewId) {
        sidebarFilters[viewId] = [];
        $('.js-view[data-id="' + viewId + '"]').find('.js-input-value').each(function () {
            $(this).prop('checked', false).change();
        });
    };

    ArchiveFilterHandler.addFilteredItem = function (text, id, value) {
        const $item = $('<span>').addClass('filtered__item').attr('data-id', id).attr('data-value', value).append($('<span>').text(text)).append($('<button>').addClass('filtered__remove js-filter-remove'));
        $filteredSection.append($item);
    };

    ArchiveFilterHandler.removeFilteredItem = function (id, value) {
        $filteredSection.find('.filtered__item[data-id="' + id + '"][data-value="' + value + '"]').remove();
    };

    ArchiveFilterHandler.updateFilterSmallText = function () {
        $('.fh__view-container--drop-down').each(function () {
            let value;
            const id = $(this).attr('data-id');
            if ($(this).find('.js-input-value').val() !== '') {
                const date = new Date($(this).find('.js-input-value').val() + '-01');
                value = monthNames[date.getMonth()] + ' ' + date.getFullYear();
            } else {
                value = 'All dates';
            }
            $('.js-small-text[data-id="' + id + '"]').text(value);
        });
    };

    ArchiveFilterHandler.showFilters = function () {
        $('body').addClass('show-filters');
    };

    ArchiveFilterHandler.hideFilters = function () {
        $('body').removeClass('show-filters');
    };

    ArchiveFilterHandler.resetSidebarFilters = function () {
        sidebarFilters.event_status = notificationFilters.event_status;
        sidebarFilters.event_type = notificationFilters.event_type;
        sidebarFilters.unavailability_type = notificationFilters.unavailability_type;
        sidebarFilters.publication_date = notificationFilters.publication_date;
    };

    ArchiveFilterHandler.updateResults = function () {
        notificationFilters.publication_date = sidebarFilters.publication_date;

        ArchiveFilterHandler.isFiltered();
        if (ArchivesHandler.scroller) ArchivesHandler.scroller.hideButton();
        if (callback !== null) {
            callback();
        }
    };

    ArchiveFilterHandler.isFiltered = function () {
        isFiltered = notificationFilters.publication_date !== null;
        if (isFiltered) {
            $filteredSection.addClass("filtered--shown");
        } else {
            $filteredSection.removeClass("filtered--shown");
        }
    };


    ArchiveFilterHandler.updateDateInput = function ($elm) {
        ArchiveFilterHandler.removeFilteredItem('publication_date', 1);
        if ($elm.val() !== '') {
            const date = new Date($(this).val() + '-01');
            const text = monthNames[date.getMonth()] + ' ' + date.getFullYear();
            ArchiveFilterHandler.addFilteredItem(text, 'publication_date', 1);
        }
        if ($(window).width() >= 760) {
            sidebarFilters['publication_date'] = $(this).val() !== '' ? $(this).val() : null;
            ArchiveFilterHandler.updateResults();
        }
    };

    return ArchiveFilterHandler;
})(window, jQuery);
