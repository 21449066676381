window.FormValidator = (function (window, $) {
    var FormValidator = function ($form, options) {
        EventEmitter.call(this);
        $form.data("Validator", this);

        var me = this;
        options = $.extend(true, {
            inputs: {},
            inputOptions: {},
        }, "object" === typeof options ? options : {});

        this.options = options;
        this.$form = $form;

        this.recheckInputs();

        $form.on("submit", function () {
            if (me.validate()) {
                if (!me.emit('submit', me.$form)) {
                    return true;
                }
            } else {
                me.emit('invalid', me.$form);
            }
            return false;
        });

        setTimeout(function () {
            me.emit('init', me);
        }, 0);
    };

    FormValidator.prototype = {
        validate: function () {
            var valid = true;

            if ('undefined' != typeof CKEDITOR) {
                for (var instanceName in CKEDITOR.instances) {
                    CKEDITOR.instances[instanceName].updateElement();
                }
            }

            $.each(this.inputs, function (_, input) {
                if (!input.validate()) {
                    valid = false;
                }
            });

            valid = this.emit('validate', this) && valid;

            return valid;
        },
        recheckInputs: function () {
            var me = this;
            this.inputs = [];
            this.$form.find("input,select,textarea").filter(":not(.js-no-validate):not([type=button]):not([type=submit])").each(function () {
                if ("undefined" === typeof $(this).data("Validator")) {
                    var input = new FormInput($(this), $.extend(true, {}, me.options.inputOptions, me.options.inputs[$(this).attr("name")]), me);
                } else {
                    var input = $(this).data("Validator");
                }
                me.inputs.push(input);

                input.on('valid', function (event) {
                    me.emit('input:valid', {
                        input: input,
                        event: event,
                    });
                });
                input.on('invalid', function (event) {
                    me.emit('input:invalid', {
                        input: input,
                        event: event,
                    });
                });
                input.on('showMessage', function (event) {
                    me.emit('input:showMessage', {
                        input: input,
                        event: event,
                    });
                });
                input.on('hideMessage', function (event) {
                    me.emit('input:hideMessage', {
                        input: input,
                        event: event,
                    });
                });
            });
        },
    };

    function FormInput($input, options, form) {
        EventEmitter.call(this);

        options = $.extend(true, {}, FormInput.defaultOptions, 'object' === typeof options ? options : {});

        this.options = options;
        this.form = form;

        this.$input = $input;
        this.type = $input.is("[data-type]") ? $input.attr("data-type") : $input.attr("type");
        var $message;

        if ($.isFunction(options.getMessageElement)) {
            $message = options.getMessageElement.call(this);
        } else {
            $message = $input.siblings(options.getMessageElement).hide();
        }

        this.$message = $message.hide();

        $input.on(options.events, this.validate.bind(this)).data('Validator', this);
    }

    FormInput.prototype = {
        getMessage: function (type) {
            return $.isFunction(this.options.getMessage) ? this.options.getMessage.call(this, type) : this.options.getMessage;
        },
        valid: function () {
            return this._validate().valid;
        },
        validate: function () {
            var result = this._validate();

            if (result.valid) {
                if (this.emit('valid', result)) {
                    if (this.options.hasSuccess && !result.hideMessage) {
                        this.showMessage(this.getMessage('success'), 'valid');
                    } else {
                        this.hideMessage();
                    }
                }
            } else {
                if (this.emit('invalid', result)) {
                    this.showMessage(result.message, 'invalid');
                }
            }

            return result.valid;
        },
        _validate: function () {
            var valid = true;
            var message = '';
            var value;
            var hideMessage = false;

            if (this.$input.is(":checkbox")) {
                value = this.$input.is(":checked");
                if (!value && !this.$input.is("[data-optional]")) {
                    valid = false;
                    message = this.getMessage('unchecked');
                } else {
                    valid = true;
                }
            } else if (this.$input.is(":radio")) {
                var name = this.$input.attr('name');
                if (!this.$input.is("[data-optional]") && this.form.$form.find('input[name="' + name + '"]:checked').length == 0) {
                    valid = false;
                    message = this.getMessage('radioUnchecked');
                } else {
                    valid = true;
                }
            } else {

                value = !this.$input.is("[data-notrim]") ? this.$input.val().trim() : this.$input.val();

                if (value.length == 0) {
                    if (!this.$input.is("[data-optional]")) {
                        valid = false;
                        message = this.getMessage('empty');
                    } else {
                        valid = true;
                        hideMessage = true;
                    }
                } else {
                    switch (this.type) {
                        case "email":
                            if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
                                valid = false;
                                message = this.getMessage('invalidEmail');
                            }
                            break;
                        case "number":
                            var number = this.$input.is("[data-number]") ? this.$input.attr("data-number") : 'both';
                            if (!$.isNumeric(value)) {
                                valid = false;
                                message = this.getMessage('invalidNumber');
                            } else {
                                value = parseFloat(value);

                                if (this.$input.is("[data-decimal]") && this.$input.is("[data-decimal-max]")) {
                                    var decimal = parseInt(this.$input.attr('data-decimal'));
                                    var max = parseInt(this.$input.attr('data-decimal-max'));
                                    var e = Math.pow(10, decimal);

                                    if (value != Math.round(value * e) / e) {
                                        valid = false;
                                        message = this.getMessage('maxDecimals');
                                        break;
                                    }

                                    value = value.toFixed(decimal);

                                    if (value.length - 1 > max) {
                                        valid = false;
                                        message = this.getMessage('maxValue');
                                        break;
                                    }
                                }

                                if (number == 'positive' && value < 0) {
                                    valid = false;
                                    message = this.getMessage('invalidPositiveNumber');
                                } else if (number == 'negative' && value > 0) {
                                    valid = false;
                                    message = this.getMessage('invalidNegativeNumber');
                                }
                            }
                            break;
                        case "tel":
                            if (!(/^\d{7,}$/).test(value.replace(/[\s()+\-\.]|ext/gi, ''))) {
                                valid = false;
                                message = this.getMessage('invalidPhone');
                            }
                            break;
                        case "datetime":
                            if (!moment(value, moment.ISO_8601).isValid()) {
                                valid = false;
                                message = this.getMessage('invalidDateFormat');
                            }
                            break;
                    }

                    if (this.$input.is("[data-min]") && (value.length < parseInt(this.$input.attr("data-min")))) {
                        valid = false;
                        message = this.getMessage('minValue');
                    }

                    if (this.$input.is("[data-max]") && (value.length > parseInt(this.$input.attr("data-max")))) {
                        valid = false;
                        message = this.getMessage('maxValue');
                    }

                    if (this.$input.is("[data-regex]")) {
                        var regex = new RegExp(this.$input.attr("data-regex"));
                        if (!regex.test(value)) {
                            valid = false;
                            message = this.getMessage('invalidRegex');
                        }
                    }
                }
            }

            var event = {
                valid: valid,
                message: message,
            };

            valid = this.emit('validate', event) && event.valid;
            return {
                valid: valid,
                message: event.message,
                hideMessage: hideMessage,
            };
        },
        showMessage: function (message, type) {
            if (!message) {
                this.hideMessage();
                return;
            }
            var event = {
                message: message,
                type: type,
            };
            if (this.emit('showMessage', event)) {
                this.$message.text(event.message).removeClass(this.options.classes.valid + ' ' + this.options.classes.invalid).addClass(event.type == 'valid' ? this.options.classes.valid : this.options.classes.invalid).show();
                if (type == 'invalid') {
                    this.$input.addClass('form__element--error');
                } else {
                    this.$input.removeClass('form__element--error');
                }
            }

        },
        hideMessage: function () {
            if (this.emit('hideMessage', {})) {
                this.$message.hide();
                this.$input.removeClass('form__element--error');
            }
        },
    };
    jQuery.extend(true, FormValidator.prototype, EventEmitter.prototype);
    jQuery.extend(true, FormInput.prototype, EventEmitter.prototype);

    FormValidator.attach = function ($form, options) {
        return new FormValidator($form, options);
    };

    FormInput.defaultOptions = {
        messages: {
            empty: 'This value is required',
            unchecked: 'This value is required',
            invalidEmail: 'Please enter a valid email address',
            success: '',
            invalidNumber: 'Please enter a number',
            invalidPositiveNumber: 'Please enter a positive number',
            invalidNegativeNumber: 'Please enter a negative number',
            invalidPhone: 'Please enter a valid phone number',
            minValue: 'Please enter the minimum amount required',
            maxValue: 'You have enter more information than is accepted please reduce this',
            invalidRegex: 'You have not enter data that matched the regex type',
            radioUnchecked: 'Please select an option',
            maxDecimals: 'You have entered too many decimal places for this number, the maximum allowed is 4',
            invalidDateFormat: 'Please enter a date that is in the following format: YYYY-MM-DD HH:MM:SS',
        },
        hasSuccess: true,
        getMessage: function (type) {
            return this.options.messages[type];
        },
        getMessageElement: '.js-inline-message',
        events: 'change blur',
        classes: {
            invalid: 'form__message--invalid',
        },
    };

    FormValidator.FormInput = FormInput;

    return FormValidator;
})(window, jQuery);
