class AutoCompleteInput {
    constructor($wrap, url, emptyText) {
        this.$wrap = $wrap;
        this.$input = $wrap.find(".js-autocomplete-input");
        this.$list = $wrap.find(".js-autocomplete-holder");
        this.url = url;
        this.emptyText = emptyText;

        this.bindEvents();
    }

    bindEvents() {
        this.$input.on("focus input", () => this.updateAutoCompleteList(this.getValue()))
            .on("keydown", (e) => {
                if (e.which === 13) {
                    return false;
                }
            }).on("blur", () => this.beginClose());

        const me = this;

        this.$list.on("click", ".js-autocomplete-value", function () {
            me.cancelClose();
            me.$input.val($(this).attr("data-value"));
            me.closeResults();
            me.$input.change();
            return false;
        });
    }

    getValue() {
        return this.$input.val();
    }

    updateAutoCompleteList(value) {
        this.cancelClose();

        if (value === "") {
            this.closeResults();
            return;
        }

        if (this.lastValue === value) {
            this.openResults();
            return;
        }
        this.lastValue = value;


        this.$list.html(<span class="inline-loader inline-loader--loading" />);
        this.openResults();

        this.getAutoCompleteValues(value).then((results) => {
            if (value !== this.getValue()) return;

            if (results.length > 0) {
                this.$list.html(
                    <ul class="fh__auto-complete-list">
                        {results.map((id) => AutoCompleteInput.get_list_item(id))}
                    </ul>,
                );
            } else {
                this.$list.html(
                    <p class="fh__auto-complete-message fh__auto-complete-message--info">{this.emptyText}</p>,
                );
            }
        }).catch((e) => {
            this.$list.html(
                <p class="fh__auto-complete-message fh__auto-complete-message--error">{e.message}</p>,
            );
        });
    }

    getAutoCompleteValues(value) {
        return Promise.all([Ajax.get(this.url, {query: value}), new Promise((res) => setTimeout(res, 500))]).then((args) => {
            return args[0];
        });
    }

    closeResults() {
        this.$list.removeClass("fh__auto-complete--show");
    }

    openResults() {
        this.$list.addClass("fh__auto-complete--show");
    }

    beginClose() {
        this.closeTimeout = setTimeout(() => this.closeResults(), 600);
    }

    cancelClose() {
        if (this.closeTimeout) clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
    }

    static get_list_item(data) {
        let id;
        let name;
        if ("string" === typeof data) {
            id = data;
        } else {
            id = data.id;
            name = data.name;
        }

        return (
            <li class="fh__auto-complete-list-item">
                <button class="fh__auto-complete-button js-autocomplete-value" data-value={id}>{
                    name !== null ? ([<strong>{name}</strong>, <small>{id}</small>]) : id
                }</button>
            </li>
        );
    }
}
