window.Page = (function (window, $) {

    var Page = {
        _pages: {},
        $currentPage: false,
    };

    Page.init = function () {
        Page.handlePage(window.location.pathname, $(".outer-container"));
    };

    /**
     * Register the callback for the url(s) provided
     * @param urls String[] Can be regex
     * @param callback function
     */
    Page.register = function (urls, callback) {
        if (!$.isArray(urls)) urls = [urls];

        $.each(urls, function (_, url) {
            if ("string" === typeof url) url = new RegExp("^" + url + "$");
            var pattern = url.toString();
            Page._pages[pattern.substr(1, pattern.length - 2)] = callback;
        });
    };

    Page.handlePage = function (url, $page) {
        Page.$currentPage = $page;

        var handler = false;
        var regex;
        $.each(Page._pages, function (pattern, callback) {
            regex = new RegExp(pattern);
            if (regex.test(url)) {
                handler = callback;
                return false;
            }
        });

        if ("function" === typeof handler) {
            var regexData = regex.exec(url);
            regexData.shift();
            try {
                handler(regexData);
            } catch (e) {
                console.error("Page handler caused error: ", e);
            }
        } else {
            console.log("No page handler for ", url);
        }
    };

    return Page;
})(window, jQuery);
