window.NotificationFeedHandler = (function (window, $) {
    const NotificationFeedHandler = {};
    const limit = 10;
    let nextUrl = null;
    let $notificationHolder = null;
    let checkMorejQXHR = null, updatejQXHR = null;
    let lastRequest = null;
    let lastRecentCheck = null;
    let notificationsChecker = null;
    let scroller;
    let $tab;

    NotificationFeedHandler.init = function (url = null) {
        nextUrl = url;
        MessageHandler.init();

        $notificationHolder = $('.js-notification-holder');
        $tab = $(".js-tab-content[data-tab='feed']");

        NotificationFeedHandler.bindEvents();

        scroller = new InfiniteScrollHandler(NotificationFeedHandler.loadMore, $tab);

        FilterHandler.init(NotificationFeedHandler.updateFilters);

        lastRecentCheck = (new Date()).getTime() / 1000;
        notificationsChecker = setInterval(NotificationFeedHandler.checkForNew, 1000 * 15);

        if (url === null) {
            NotificationFeedHandler.updateFilters();
        }
    };

    NotificationFeedHandler.bindEvents = function () {
        $tab.on('click', '.js-retry-check-more', function () {
            if (lastRequest !== null) {
                lastRequest();
            }

            return false;
        }).on('click', '.js-load-more', function () {
            scroller.startLoading();
        });
    };


    NotificationFeedHandler.updateFilters = function () {
        lastRequest = NotificationFeedHandler.updateFilters;
        if (updatejQXHR) updatejQXHR.abort();

        scroller.hideButton();

        NotificationFeedHandler.abortCheckNotifications();

        $notificationHolder.addClass('notification-holder--loading');

        updatejQXHR = Ajax.get('remits', FilterHandler.getActiveFilters());

        Promise.all([updatejQXHR, new Promise((resolve) => setTimeout(resolve, 600))]).then(function (args) {
            MessageHandler.hideMessage();
            let data = args[0];
            $notificationHolder.removeClass('notification-holder--loading').html('');
            if (data.results.length > 0) {
                $(data.results).each(function (index, value) {
                    $notificationHolder.append($(value.content).addClass(index <= 3 ? 'notification--main notification--open' : ''));
                });
            } else {
                $notificationHolder.append(
                    <div className="notification notification--none">
                        <h2>No notifications</h2>
                        <p>There are no notifications to show with your current configuration of filters.</p>
                    </div>,
                );
            }
            nextUrl = data.next;
            if (data.results.length >= limit) {
                scroller.showButton();
            } else {
                scroller.hideButton();
            }
            scroller.stopLoading();
        }, function () {
            MessageHandler.showError('We couldn\'t load any filtered notifications, please <a href="#" class="js-retry-check-more">retry</a> to load notification that match the filters you have applied.');
        });
    };

    NotificationFeedHandler.loadMore = function () {
        lastRequest = NotificationFeedHandler.loadMore;
        return Ajax.get(nextUrl, {}).then(function (data) {
            MessageHandler.hideMessage();

            $(data.results).each(function (index, value) {
                $notificationHolder.append(value.content);
            });
            if (data.results.length < limit) {
                scroller.stopLoading();
                scroller.hideButton();
            }
            nextUrl = data.next;
        }, function () {
            MessageHandler.showError('We couldn\'t load any new notifications, please <a href="#" class="js-retry-check-more">retry</a> to load new notifications.');
        });
    };

    NotificationFeedHandler.checkForNew = function () {
        lastRequest = NotificationFeedHandler.checkForNew;
        NotificationFeedHandler.abortCheckNotifications();
        checkMorejQXHR = Ajax.get('remits/latest', {
            from: lastRecentCheck,
        });
        checkMorejQXHR.then(function (data) {
            if (data.results.length > 0) {
                $(data.results).each(function (index, value) {
                    const $newRemit = $(value.content);
                    $newRemit.addClass('notification--main notification--open');
                    $notificationHolder.prepend($newRemit);
                });
            }
            lastRecentCheck = data.from.timestamp;
        }, function () {
            MessageHandler.showError('We couldn\'t check for new notifications, please <a href="#" class="js-retry-check-more">retry</a> or reload this page to load new notifications.');
        });
    };

    NotificationFeedHandler.abortCheckNotifications = function () {
        try {
            if (checkMorejQXHR) checkMorejQXHR.abort();
        } catch (e) {
        }
    };

    return NotificationFeedHandler;
})(window, jQuery);
