Page.register("/contact", function (url_args) {
    var $form = $('.js-contact-form');

    function doSubmit(token) {
        var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

        Ajax.post('contact', {
            name: $form.find(".js-name").val(),
            number: $form.find(".js-number").val(),
            email: $form.find(".js-email").val(),
            message: $form.find(".js-message").val(),
            "g-recaptcha-response": token,
        }).then(function (data) {
            var message = '<div class="sm sm--contact success-message">\
                                    <span class="sm__icon sm__icon--contact"><svg><use xlink:href="/includes/svg-icons.svg?v=1#subscription-confirm-icon"></use></svg></span>\
                                    <span class="sm__info sm__info--contact">\
                                        <h4>Message sent</h4>\
                                        <p>Your enquiry has been submitted and a member of our team will get in touch shortly.</p>\
                                    </span>\
                                </div>';
            $form.html(message);
            grecaptcha.reset();
        }, function (error) {
            console.log(error);
            $btn.removeClass("btn--loading");
            $(".js-message-holder").html($('<div>').addClass('message message--error').append($('<p>').text(error.message)));
            grecaptcha.reset();
        });
    }

    FormValidator.attach($form, {
        inputs: {
            name: {
                messages: {
                    empty: 'Please enter your name',
                },
            },
            email: {
                messages: {
                    empty: 'Please enter a valid email address',
                },
            },
            message: {
                messages: {
                    empty: 'Please enter a message',
                },
            },
        },
    }).on('submit', function ($form) {
        $(".js-message-holder").empty();
        var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");
        try {
            window.onRecaptchaSubmit = doSubmit;
            grecaptcha.execute();
        } catch (e) {
            console.warn(e);
            $btn.removeClass("btn--loading");
            $(".js-message-holder").html($('<div>').addClass('message message--error').append($('<p>').text("There was an error submitting your message. Please try again later.")));
        }
    });
});
