window.ImagePreloader = (function (window) {
    var ImagePreloader = {};


    ImagePreloader.preload = function (images) {
        return Promise.all(images.map(ImagePreloader._preloadImage));
    };

    ImagePreloader._preloadImage = function (url) {
        return new Promise(function (resolve) {
            var image = new Image;

            image.onload = image.onerror = function () {
                resolve();
            };

            image.src = url;
        });
    };

    return ImagePreloader;
})(window);
