window.FilterHandler = (function (window, $) {
    const FilterHandler = {};

    let initialised = false;

    let callbacks = [];

    let activeFilters = {};
    let sidebarFilters = {};
    let originalFilters;

    let dateInputs = {};
    let rangeInputs = {};

    let $section;

    FilterHandler.init = function (initCallback) {
        callbacks.push(initCallback);

        if (initialised) return;
        initialised = true;
        FilterHandler.bindEvents();

        originalFilters = $(".js-filter-form").serializeJSON();

        FilterHandler.updateSidebarFilterValues();
        activeFilters = $.extend(true, {}, sidebarFilters);
    };

    FilterHandler.bindEvents = function () {
        $(".js-date-wrap").each(function () {
            dateInputs[$(this).attr("data-name")] = new DateRangeInput($(this));
        });
        let unit = $("input[name=unit]:checked").attr("value");
        $(".js-slider-wrap").each(function () {
            let range = new RangeInput($(this));
            range.setUnit(unit);
            rangeInputs[$(this).attr("data-name")] = range;
        });
        new AutoCompleteInput($(".js-event-id-wrap"), "events/auto-complete", "No event matches that Event ID");
        new AutoCompleteInput($(".js-participant-id-wrap"), "events/participants/auto-complete", "No market participants found");
        new AutoCompleteInput($(".js-balancing-zones-id-wrap"), "events/balancing-zones/auto-complete", "No balancing zones found");

        $('body').on('click', '.js-show-mobile-filter', function () {
            FilterHandler.showFilters();
            return false;
        }).on('click', '.js-toggle-filter', function () {
            if ($('body').is('.show-filters')) {
                FilterHandler.hideFilters();
            } else {
                FilterHandler.showFilters();
            }
            return false;
        }).on("click", ".js-view-mobile-section", function () {
            FilterHandler.showMobileSection($(this).attr("data-mobile-section"));
            return false;
        }).on('click touch', function (e) {
            const $target = $(e.target);
            if ($(window).width() < 760 && !$target.is("fh") && $target.parents(".fh").length === 0) {
                FilterHandler.hideFilters();
            }
        }).on("click", ".js-mobile-section-cancel", function () {
            FilterHandler.cancelSection($section.attr("data-mobile-section"));

            FilterHandler.showParentSection();
            return false;
        }).on("click", ".js-mobile-section-clear", function () {
            FilterHandler.clearSection($section.attr("data-mobile-section"));

            FilterHandler.showParentSection();
            return false;
        }).on("click", ".js-mobile-section-apply", function () {
            FilterHandler.updateSidebarFilterValues();

            FilterHandler.showParentSection();
            return false;
        }).on("click", ".js-cancel-filters", function () {
            FilterHandler.showMobileSection('main');
            FilterHandler.hideFilters();
            sidebarFilters = $.extend(true, {}, activeFilters);
            FilterHandler.updateMobilePreviews();

            FilterHandler.cancelAllSections();
            return false;
        }).on("click", ".js-apply-filters", function () {
            FilterHandler.applyUpdatedFilters();
            FilterHandler.hideFilters();
            return false;
        }).on("change", "input[name=unit]", () => {
            let val = $("input[name=unit]:checked").attr("value");
            RangeInput.instances.forEach((input) => {
                input.setUnit(val);
            });
        }).on("change value-changed", "input", () => {
            if ($(window).width() >= 760) {
                FilterHandler.updateSidebarFilterValues();
                FilterHandler.applyUpdatedFilters();
            }
        }).on("click", ".js-clear-filters", function () {
            if ($(this).is(".fh__clear-all--disabled")) return false;
            FilterHandler.clearAllSections();

            if ($(window).width() < 760) {
                FilterHandler.hideFilters();
            }
            return false;
        }).on("click", "input[name='all-event-type[]']", "change", function () {
            $("input[name='event_type[]']").prop("checked", false);
            $(this).prop("checked", true);
        }).on("click", "input[name='event_type[]']", "change", function () {
            $("input[name='all-event-type[]']").prop("checked", $("input[name='event_type[]']:checked").length === 0);
        });


        $(".js-filter-form").on("submit", () => {
            if ($(window).width() >= 760) {
                FilterHandler.updateSidebarFilterValues();
                FilterHandler.applyUpdatedFilters();
            }
            return false;
        });
    };

    FilterHandler.applyUpdatedFilters = function () {
        activeFilters = $.extend(true, {}, sidebarFilters);

        FilterHandler.showMobileSection('main');
        FilterHandler.updateResults();
    };

    FilterHandler.cancelSection = function (section, updatePreviews = true) {
        let $section = $(".js-mobile-section[data-mobile-section='" + section + "']");

        if ($section.is(".fh__view-container--radio")) {

            if ("undefined" !== typeof sidebarFilters[section]) {
                $section.find("input:radio[value='" + sidebarFilters[section] + "']").prop("checked", true);
            } else {
                $section.find("input:radio[value='']").prop("checked", true);
            }
        } else if ($section.is(".fh__view-container--checkboxes")) {
            $section.find("input:checkbox").prop("checked", false);

            if (sidebarFilters[section] instanceof Array) {
                sidebarFilters[section].forEach((id) => $section.find("input:checkbox[value='" + id + "']").prop("checked", true));
            }
        } else if ($section.is(".fh__view-container--date-range")) {
            let dateRange = dateInputs[section];

            dateRange.$fromPicker.val(sidebarFilters[section + "_from"]);
            dateRange.$toPicker.val(sidebarFilters[section + "_to"]);
            dateRange.setPreset('custom');
            dateRange.updatePreview();
        } else if ($section.is(".fh__view-container--date-mobile-inner")) {
            $section.find("input").val(sidebarFilters[section]).change();
        } else if ($section.is(".fh__view-container--capacity")) {
            let range = rangeInputs[section];

            let from = sidebarFilters[section + "_from"];
            let to = sidebarFilters[section + "_to"];

            if ('undefined' === typeof from || 'undefined' === typeof to) {
                range.reset();
            } else {
                range.setValue(from, to);
            }
        } else {
            $section.find("input[name='" + section + "']").val(sidebarFilters[section]);
        }

        if (updatePreviews) FilterHandler.updateMobilePreviews();
    };

    FilterHandler.clearAllSections = function () {
        $(".js-view-mobile-section").each(function () {
            FilterHandler.clearSection($(this).attr("data-mobile-section"), false);
        });
        FilterHandler.updateMobilePreviews();
        FilterHandler.applyUpdatedFilters();
    };


    FilterHandler.clearSection = function (section, updateValues = true) {
        let $section = $(".js-mobile-section[data-mobile-section='" + section + "']");

        if ($section.is(".fh__view-container--radio")) {
            $section.find("input:radio[value='']").prop("checked", true);
        } else if ($section.is(".fh__view-container--checkboxes")) {
            $section.find("input:checkbox").prop("checked", false);
            $section.find("input[name='all-" + section + "']:checkbox").prop("checked", true);
        } else if ($section.is(".fh__view-container--date-range")) {
            let dateRange = dateInputs[section];

            dateRange.setPreset('all');
            dateRange.updatePreview();
        } else if ($section.is(".fh__view-container--date-mobile")) {
            let dateRange = dateInputs[/(.+)-mobile/.exec(section)[1]];

            dateRange.setPreset('all');
            dateRange.updatePreview();
        } else if ($section.is(".fh__view-container--date-mobile-inner")) {
            $section.find("input").val("").change();
        } else if ($section.is(".fh__view-container--capacity")) {
            let range = rangeInputs[section];

            range.reset();
        } else {
            $section.find("input[name='" + section + "']").val("");
        }

        if (updateValues) {
            FilterHandler.updateSidebarFilterValues();
            FilterHandler.updateMobilePreviews();
        }
    };

    FilterHandler.cancelAllSections = function () {
        $(".js-view-mobile-section").each(function () {
            FilterHandler.cancelSection($(this).attr("data-mobile-section"), false);
        });
        FilterHandler.updateMobilePreviews();
    };


    FilterHandler.showMobileSection = function (section) {
        $(".js-mobile-section").removeClass("fh__view-container--shown");

        $section = $(".js-mobile-section[data-mobile-section='" + section + "']").addClass("fh__view-container--shown");

        let type = $section.attr("data-type");
        if (!type) type = 'inner';

        $(".js-action-holder").hide().filter("[data-type='" + type + "']").css("display", "");
    };

    FilterHandler.updateMobilePreviews = function () {
        $(".fh__button").each(function () {
            let $this = $(this);
            let section = $this.attr("data-mobile-section");
            let $section = $(".js-mobile-section[data-mobile-section='" + section + "']");
            let value = null;

            if ($section.is(".fh__view-container--radio")) {
                value = $section.find("input:checked").parent().find("label").text();
            } else if ($section.is(".fh__view-container--checkboxes")) {
                let values = $section.find("input:checked").map(function () {
                    return $(this).parent().find("label").text().trim();
                }).get();

                if (values.length > 3) {
                    value = values.length + " selected";
                } else {
                    value = values.join(", ");
                    if (value.length > 50) {
                        value = values.length + " selected";
                    }
                }
            } else if ($section.is(".fh__view-container--date-range")) {
                let dateRange = dateInputs[section];

                if (dateRange) {
                    value = dateRange.getPreviewText();
                } else {
                    value = '';
                }
            } else if ($section.is(".fh__view-container--capacity")) {
                let range = rangeInputs[section];

                if (range) {
                    value = range.getTextValue();
                } else {
                    value = '';
                }
            } else if ($section.is(".fh__view-container--date-mobile")) {
                let dateRange = dateInputs[/(.+)-mobile/.exec(section)[1]];

                if (dateRange) {
                    value = dateRange.getPreviewText();
                } else {
                    value = '';
                }
            } else if (section === 'capacity-nav') {
                if (RangeInput.any_selected()) {
                    value = "Filters Selected";
                } else {
                    value = "All";
                }
            } else {
                value = $section.find("input").val();
            }

            let $preview = $this.find(".js-mobile-preview");

            if (!value) {
                $preview.text($preview.attr("data-default"));
            } else {
                $preview.text(value);
            }
        });
    };

    FilterHandler.showParentSection = function () {
        let parent = $section.attr("data-parent-section");
        FilterHandler.updateMobilePreviews();
        FilterHandler.showMobileSection("undefined" === typeof parent ? 'main' : parent);
    };

    FilterHandler.getActiveFilters = function () {
        return activeFilters;
    };

    FilterHandler.showFilters = function () {
        $('body').addClass('show-filters');
        $('.page__contents').css('min-height', $('.js-filter-form').height());
        FilterHandler.showMobileSection('main');
    };

    FilterHandler.hideFilters = function () {
        $('body').removeClass('show-filters');
        DateRangeInput.close_all();
        $('.page__contents').css('min-height', '');
    };

    FilterHandler.updateSidebarFilterValues = function () {
        sidebarFilters = $(".js-filter-form").serializeJSON();

        $(".fh__clear-all").toggleClass("fh__clear-all--disabled", obj_compare(sidebarFilters, originalFilters));

        FilterHandler.updateMobilePreviews();
    };

    FilterHandler.updateResults = function () {
        callbacks.forEach((callback) => {
            if ("function" === typeof callback) {
                callback();
            }
        });
    };

    return FilterHandler;
})(window, jQuery);
