Page.register("/unsubscribe", function (url_args) {

    MessageHandler.init();

    $('.js-unsubscribe-form').on('submit', function () {
        var $form = $(this);
        if ($form.find('.js-action:checked').length == 0) {
            MessageHandler.showError('Please selected an option.');
            return false;
        }

        if ($form.find('.js-action:checked').val() == 'cancel') {
            var $html = $('<div>')
                .append($('<h2>').text('Cancellation Successful'))
                .append($('<p>').text('You have chosen to remain receiving the GB REMIT Central Collection & Publication Service email notifications'))
                .append($('<button>').attr('type', 'button').attr('data-key', $form.find(".js-key").val()).attr('data-token', $form.find(".js-token").val()).attr('data-action', 'unsubscribe').addClass('btn btn--blue js-action-button').text('Unsubscribe'));
            $form.html($html);
            return false;
        }

        MessageHandler.hideMessage();
        var $btn = $form.find(".btn[type='submit']").addClass("btn--loading");

        Ajax.delete('subscription/' + $form.find(".js-token").val(), {
            key: $form.find(".js-key").val(),
        }).then(function (data) {
            var $html = $('<div>')
                .append($('<h2>').text('Unsubscription Successful'))
                .append($('<p>').text('You have unsubscribed from GB REMIT Central Collection & Publication Service and will no longer receive email notifications.'))
                .append($('<button>').attr('type', 'button').attr('data-key', $form.find(".js-key").val()).attr('data-token', $form.find(".js-token").val()).attr('data-action', 'resubscribe').addClass('btn btn--blue js-action-button').text('Resubscribe'));
            $form.html($html);
        }, function (error) {
            MessageHandler.showError('Something went wrong trying to unsubscribe you from our system. ' + error.message);
            $btn.removeClass('btn--loading');
        });

        return false;
    });

    $('body').on('click', '.js-action-button', function () {
        var $token = $(this).attr('data-token');
        var $key = $(this).attr('data-key');
        var $btn = $('.js-unsubscribe-form').find(".btn").addClass("btn--loading");
        if ($(this).attr('data-action') == 'unsubscribe') {
            Ajax.delete('subscription/' + $token, {
                key: $key,
            }).then(function (data) {
                var $html = $('<div>')
                    .append($('<h2>').text('Unsubscription Successful'))
                    .append($('<p>').text('You have unsubscribed from GB REMIT Central Collection & Publication Service and will no longer receive email notifications.'))
                    .append($('<button>').attr('type', 'button').attr('data-key', $key).attr('data-token', $token).attr('data-action', 'resubscribe').addClass('btn btn--blue js-action-button').text('Resubscribe'));
                $('.js-unsubscribe-form').html($html);
            }, function (error) {
                MessageHandler.showError('Something went wrong trying to unsubscribe you from our system. ' + error.message);
                $btn.removeClass('btn--loading');
            });
        } else if ($(this).attr('data-action') == 'resubscribe') {
            Ajax.post('subscription/' + $token + '/resubscribe/', {
                key: $key,
            }).then(function (data) {
                var $html = $('<div>')
                    .append($('<h2>').text('Resubscription Successful'))
                    .append($('<p>').text('You have resubscribed to GB REMIT Central Collection & Publication Service and will no receive email notifications again.'))
                    .append($('<button>').attr('type', 'button').attr('data-key', $key).attr('data-token', $token).attr('data-action', 'unsubscribe').addClass('btn btn--blue js-action-button').text('Unsubscribe'));
                $('.js-unsubscribe-form').html($html);
            }, function (error) {
                MessageHandler.showError('Something went wrong trying to resubscribe you to our system. ' + error.message);
                $btn.removeClass('btn--loading');
            });
        }

    });

});
