window.ARCHIVE_START_DATE = '2016-12-31';
$(function () {
    Page.init();
    SubscribeForm.init();
    NavigationHandler.init();
});


function obj_compare(obj1, obj2, noOpposite = null) {
    if (typeof obj1 !== typeof obj2) return false;

    let seenKeys = {};
    let same = true;

    $.each(obj1, function (key, val) {
        if ("undefined" !== typeof seenKeys[key]) {
            return;
        }
        seenKeys[key] = true;

        if (typeof val !== typeof obj2[key]) {
            same = false;
        } else if ("object" === typeof val) {
            same = same && obj_compare(val, obj2[key]);
        } else if (val !== obj2[key]) {
            same = false;
        }

        if (!same) return false;
    });

    return same && (noOpposite === null || obj_compare(obj2, obj1, true));
}
